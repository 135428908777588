import enBaseTranslations from './en.json';
import deBaseTranslations from './de.json';
import enKycTranslations from './en-kyc.json';
import deKycTranslations from './de-kyc.json';
import deMobileTranslations from './de-mobile.json';
import enMobileTranslations from './en-mobile.json';

const enTranslations = {
  ...enBaseTranslations,
  ...enKycTranslations,
  ...enMobileTranslations,
};
const deTranslations = {
  ...deBaseTranslations,
  ...deKycTranslations,
  ...deMobileTranslations,
};

export { enTranslations, deTranslations };
